<template>
    <div></div>
  </template>
  
  <script>
  export default {
  mounted() {
    location.href = 'https://wx.playhy.com/box/#/'
    // console.log('sdfsdf')
  }
  }
  </script>
  
  <style scoped>
  
  </style>
  